<template>
  <MDBCol class="text-center" col="12" sm="6" xl="3">
    <Icon :name="props.icon" size="40" class="icon m-3" />
    <h3 class="h4 fw-lighter">{{ props.title }}</h3>
    <p><slot></slot></p>
  </MDBCol>
</template>

<script setup lang="ts">
import { MDBCol } from "mdb-vue-ui-kit";

const props = defineProps<{
  title: string;
  icon: string;
}>();
</script>

<style lang="scss" scoped>
@use "../assets/styles/variables" as v;

.icon {
  color: v.$primary;
}
</style>
