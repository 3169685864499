<template>
  <MDBCol col="12">
    <Hero />
    <MDBContainer>
      <MDBRow class="py-5">
        <SkillBox title="Web Development" icon="tabler:device-desktop-code">
          I have many years of web development experience ranging from simple
          websites to large, scalable web applications. As well as HTML, CSS and
          JS, I am familiar with PHP, SCSS and command line interfaces. I have
          experience setting up and configuring servers, including on cloud
          providers such as AWS, GCP and Azure, as well as server software such
          as WHM/CPanel, Apache, IIS and Node.js.
        </SkillBox>

        <SkillBox title="Video Production" icon="tabler:video">
          Video has been my passion since childhood. From short films to
          corporate videos, I have experience shooting cinematic scenes,
          conducting video interviews and recording stock footage. I’ve worked
          filming live sporting and music events. I have many years experience
          working various camera and with the Adobe suite.
        </SkillBox>

        <SkillBox title="Sound & Lighting" icon="tabler:microphone">
          I have experience recording music in studios as well as sound
          engineering for live events. I’ve used a number of digital and
          analogue sound desks as well as DAW’s such as Pro Tools. I’ve dealt
          with lighting installation and setup in multiple buildings as well as
          for live events. I also install A/V setups and networking
          infrastructure.
        </SkillBox>

        <SkillBox title="Drone Operations" icon="tabler:drone">
          I’m a registered and qualified drone pilot, having completed my A2
          Certificate of Competence in 2020. I’m currently undertaking my GVC
          which allows for more complex operations. Drone operations include
          photography, videography, site surveys and aerial photogrammetry.
        </SkillBox>
      </MDBRow>
    </MDBContainer>
  </MDBCol>
</template>

<script setup lang="ts">
import { MDBContainer, MDBRow, MDBCol } from "mdb-vue-ui-kit";

const { title, description } = useAppConfig();
const { homepage } = useRuntimeConfig().public;
useSeoMeta({
  description,
  ogTitle: title,
  ogDescription: description,
  ogImage: "/images/header.webp",
  ogUrl: String(homepage),
  twitterTitle: title,
  twitterDescription: description,
  twitterImage: "/logo.svg",
  twitterCard: "summary",
});
</script>
